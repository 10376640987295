var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "mt-10" },
    [
      _c(
        "v-row",
        { staticClass: "col-12 mb-16 ml-0 mr-0" },
        [
          _c(
            "v-col",
            {
              staticClass:
                " col-xs-12 col-md-6 left-col mt-xs-1 order-2 order-md-1",
            },
            [
              _c("div", { attrs: { id: "mechanic-container" } }, [
                _c("h1", { attrs: { id: "mechanic-title" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("BecomeMechanicPage.Title")) + " "
                  ),
                ]),
                _c("p", { attrs: { id: "mechanic-description" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("BecomeMechanicPage.Description")) + " "
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "text-none",
                    attrs: { href: "mailto:tonyb@ucarmobile.com" },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "white--text text-none text-center mobile-btn",
                        attrs: {
                          elevation: "0",
                          color: "#12945F",
                          height: "48",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("ContactUs")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              staticClass:
                " col-xs-12 col-md-6 left-col mt-xs-1 order-1 order-md-2",
            },
            [
              _c("v-img", {
                staticClass: "mt-12",
                attrs: {
                  "max-width": "572",
                  "lazy-src": require("../assets/images/become-mechanic-hero-img.webp"),
                  src: require("../assets/images/become-mechanic-hero-img.webp"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }